<template>
  <div>
    <!-- The modal -->
    <b-modal
      :id="id"
      ref="modal"
      title="Edytuj produkt"
      ok-title="Zaktualizuj"
      cancel-title="Anuluj"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      @cancel="resetForm"
      @ok="handleModalOk"
      @shown="fillModal"
    >
      <b-form novalidate>
        <b-form-group
          label="name"
          label-for="name"
          invalid-feedback="To pole jest wymagane"
          :state="null"
        >
          <b-form-input id="input" v-model="form.name"></b-form-input>
        </b-form-group>

        <div class="form-group">
          <Select
            id="insurance_group_uuid"
            v-model="form.insurance_group_uuid"
            :options="insuranceGroupsForSelect"
            label="insurance_group_uuid"
            name="insurance_group_uuid"
          />
        </div>

        <div class="form-group">
          <Select
            id="subject_insurance_uuid"
            v-model="form.subject_insurance_uuid"
            :options="subjectInsurancesForSelect"
            label="subject_insurance_uuid"
            name="subject_insurance_uuid"
          />
        </div>

        <div class="form-group">
          <Select
            id="insurer_uuid"
            v-model="form.insurer_uuid"
            :options="insurersForSelect"
            label="insurer_uuid"
            name="insurer_uuid"
          />
        </div>

        <b-form-group>
          <b-form-checkbox
            id="is_sale"
            v-model="form.is_sale"
            name="is_sale"
            :value="true"
            :unchecked-value="false"
            class="label-cb py-2"
          >
            is_sale
          </b-form-checkbox>
        </b-form-group>

        <b-form-group>
          <b-form-checkbox
            id="is_visible"
            v-model="form.is_visible"
            name="is_visible"
            :value="true"
            :unchecked-value="false"
            class="label-cb py-2"
          >
            is_visible
          </b-form-checkbox>
        </b-form-group>

        <b-form-group>
          <b-form-checkbox
            id="external"
            v-model="form.external"
            name="external"
            :value="true"
            :unchecked-value="false"
            class="label-cb py-2"
          >
            external
          </b-form-checkbox>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { PRODUCT_EDIT } from "@/core/services/store/insurer.module";
import { baseURL } from "@/core/services/api.service.js";
import { mapGetters } from "vuex";
import _ from "lodash";
import Select from "@/view/content/components/select/Select";

export default {
  name: "ModalProductEdit",
  components: { Select },
  props: ["id", "uuid"],
  data() {
    return {
      product: null,
      formState: null,
      form: {
        insurer_uuid: "",
        insurance_group_uuid: "",
        subject_insurance_uuid: "",
        name: "",
        is_sale: false,
        is_visible: false,
        external: false
      }
    };
  },
  computed: {
    ...mapGetters(["products"]),
    ...mapGetters(["insurers", "insuranceGroups", "subjectInsurances"]),
    getBaseURL() {
      return baseURL;
    },
    insurersForSelect() {
      const insurersForSelect = [];
      for (const insurer of this.insurers) {
        let v = {
          text: insurer.name,
          value: insurer.uuid
        };
        insurersForSelect.push(v);
      }
      return insurersForSelect;
    },
    insuranceGroupsForSelect() {
      const insuranceGroupsForSelect = [];
      for (const ig of this.insuranceGroups) {
        let v = {
          text: ig.name,
          value: ig.uuid
        };
        insuranceGroupsForSelect.push(v);
      }
      return insuranceGroupsForSelect;
    },
    subjectInsurancesForSelect() {
      const subjectInsurancesForSelect = [];
      for (const si of this.subjectInsurances) {
        let v = {
          text: si.name,
          value: si.uuid
        };
        subjectInsurancesForSelect.push(v);
      }
      return subjectInsurancesForSelect;
    }
  },
  mounted() {},
  methods: {
    resetForm() {
      // this.form.insurer_uuid = "";
      // this.form.insurance_group_uuid = "";
      // this.form.subject_insurance_uuid = "";
      // this.form.name = "";
      // this.form.is_sale = false;
      // this.form.is_visible = false;
      // this.form.external = false;
    },
    validateForm() {
      this.formState = true;
    },
    handleModalOk(event) {
      event.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      this.validateForm();
      if (this.formState) {
        let formToSend = this.form;
        this.$store.dispatch(PRODUCT_EDIT, {
          uuid: this.uuid,
          ...formToSend
        });
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide(this.id);
        });
      }
    },
    fillModal() {
      //console.log("this.insurersForSelect: ");
      //console.log(this.insurersForSelect);
      const product = _.find(this.products, o => {
        return o.uuid === this.uuid;
      });
      if (product !== undefined) {
        this.product = product;
        //console.log("zaczytany produkt: ");
        //console.log(product);
        this.form.insurer_uuid = product.insurer_uuid;
        this.form.insurance_group_uuid = product.insurance_group_uuid;
        this.form.subject_insurance_uuid = product.subject_insurance_uuid;
        this.form.name = product.name;
        this.form.is_sale = product.is_sale;
        this.form.is_visible = product.is_visible;
        this.form.external = product.external;
        //console.log("form wypelniony danymi z produktu: ");
        //console.log(this.form);
      }
    }
  }
};
</script>
