<template>
  <div class="container-fluid px-0 h-100">
    <SettingsProductView />
  </div>
</template>

<script>
import SettingsProductView from "../../../content/settings/product/View";
import {
  INSURANCE_GROUPS_LIST,
  INSURER_LIST,
  SUBJECT_INSURANCES_LIST,
  PRODUCTS_LIST
} from "@/core/services/store/insurer.module";

export default {
  name: "SettingsProductPageView",
  components: { SettingsProductView },
  mounted() {
    this.$store.dispatch(INSURER_LIST);
    this.$store.dispatch(SUBJECT_INSURANCES_LIST);
    this.$store.dispatch(INSURANCE_GROUPS_LIST);
    this.$store.dispatch(PRODUCTS_LIST);
  }
};
</script>
